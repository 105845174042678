<template>
    <div style="min-width:1280px">
        <el-header class="header d-flex-center bg-white position-sticky-top box-shadow font-bold color-333 font-14">
            <div index="1" class="item d-inline-flex-center" @click="$nav.push('/')">
            <!-- <i class="icon icon-home icon-18 mr-5" /> -->
            <!-- {{ $t('navbar.home') }} -->
                <el-image :src="logo" style="width:160px;height:54px"></el-image>
            </div>
            <!-- <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/subscribe')"><i class="icon icon-stars icon-18 mr-5" />{{ $t('navbar.subscribe') }}</div>
            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/fund')"><i class="icon icon-payments icon-18 mr-5" />{{ $t('navbar.fund') }}</div> -->

            <!-- 新增 -->
            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/list/new_list')"><i class="icon icon-nav-new_coin icon-18 mr-5" />{{ $t('navbar.new1') }}</div>

            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/list/newmarket_list')"><i class="icon icon-nav-market icon-18 mr-5" />{{ $t('navbar.new_new') }}</div>

            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/list/market_list')"><i class="icon icon-nav-market icon-18 mr-5" />{{ $t('navbar.new2') }}</div>
            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/list/trading_list')"><i class="icon icon-nav-trading icon-18 mr-5" />{{ $t('navbar.new3') }}</div>
            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/list/contact_list')"><i class="icon icon-nav-contract icon-18 mr-5" />{{ $t('navbar.new4') }}</div>
            <!-- 法幣隱藏 2023.2.5 -->
            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/fiat_currency/index')"><i class="icon icon-nav-fiat icon-18 mr-5" />{{ $t('navbar.new5') }}</div>
            <!-- 新增 -end -->

            <div index="3" class="item p-16  d-inline-flex-center mr-auto" @click="$nav.push('/help')"><i class="icon icon-nav-help icon-18 mr-5" />{{ $t('navbar.help') }}</div>


            <el-dropdown @command="onLangSelect">
                <span class="item p-16 d-inline-flex-center">
                    <i class="icon icon-nav-lang icon-18 mr-5" />
                    <span class="cont-bold color-333 font-14">{{ $t('navbar.language') }}</span>
                    <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu class="dropdown" slot="dropdown">
                    <el-dropdown-item command="en-US">English</el-dropdown-item>
                    <el-dropdown-item command="Latin">Latin</el-dropdown-item>
                    <el-dropdown-item command="zh-TW">繁體中文</el-dropdown-item>
                    <!-- <el-dropdown-item command="zh-CN">简体中文</el-dropdown-item> -->
                    <el-dropdown-item command="Thailand">ภาษาไทย</el-dropdown-item>
                    <el-dropdown-item command="Korea">한국어</el-dropdown-item>
                    <el-dropdown-item command="Japan">日本語</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-if="$store.state.userToken" @command="onAccountAction">
                <span class="item p-16 d-inline-flex-center" @click="onAccountAction('onAssets')">
                    <i class="icon icon-nav-myself icon-18 mr-5" />
                    <span class="cont-bold color-333 font-14">{{ $t('navbar.my_assets') }}</span>
                    <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu class="dropdown" slot="dropdown">
                    <el-dropdown-item command="onProfile">{{ $t('navbar.profile') }}</el-dropdown-item>
                    <el-dropdown-item command="onSettings">{{ $t('navbar.settings') }}</el-dropdown-item>
                    <el-dropdown-item command="onLogout">{{ $t('navbar.logout') }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div v-else index="5" class="item p-16 d-inline-flex-center" @click="$nav.push('/user/login')">
                <i class="icon icon-nav-myself icon-18 mr-5" />{{ $t('navbar.sign_in') }}
            </div>
        </el-header>
        <el-main class="main pt-0 px-0 pb-30">
            <router-view />
        </el-main>
        <el-footer class="footer bg-white pt-50">
            <nav class="nav">
                <div class="item" @click="$nav.push('/help/5')">{{ $t('footer.about_us') }}</div>
                <span>/</span>
                <!-- <div class="item" @click="$nav.push('/help/21')">{{ $t('footer.help_center') }}</div>
                <span>/</span> -->
                <div class="item" @click="$nav.push('/fees')">{{ $t('footer.fees') }}</div>
                <span>/</span>
                <div class="item" @click="$nav.push('/help/4')">{{ $t('footer.user_agreement') }}</div>
                <span>/</span>
                <div class="item" @click="$nav.push('/help/6')">{{ $t('footer.contact_us') }}</div>
                <span>/</span>
                <div class="item" @click="$nav.push('/help/22')">{{ $t('footer.qualification') }}</div>
            </nav>
            <p>{{ $t('home.title1') }} {{ $t('footer.copyright') }} © 2017-2024</p>
        </el-footer>
    </div>
</template>

<script>
import NotifyAudioFile from '@/assets/notify/1.mp3'
import logo from '@/assets/index/logo.png'
export default {
    data() {
        return {
            logo,
            activeIndex: '1',
            loading: false,
            timer: null,
            notifyNews: null,
            notifyFund: null,
            notifyAudio: null
        }
    },
    created: function() {
        this.notifyAudio = new Audio(NotifyAudioFile)
        this.notifyAudio.classList.add('d-none')
        this.notifyAudio.preload = 'auto'
        // this.fatchNotify();
        // this.timer = window.setInterval(() => {
        //     if (document.hidden) return
        //     this.fatchNotify()
        // }, 5000)
    },
    beforeDestroy: function() {
        window.clearInterval(this.timer)
    },    
    methods: {
        onLangSelect: function (locale) {
            this.$store.commit('locale', locale)
            window.location.reload()
        },
        onAccountAction: function (action){
            if(!action) return
            this[action]()
        },
        onAssets: function(){
            this.$nav.push('/user/account/assets')
        },
        onProfile: function(){
            this.$nav.push('/user/account')
        },
        onSettings: function(){
            this.$nav.push('/user/settings')
        },
        onLogout: function () {
            this.$request.get('logout')
            this.$store.commit('userToken', '')
            this.$store.commit('userId', '')
            localStorage.clear();
            if(this.$route.path !== '/'){
                this.$nav.replace('/')
            }
        },
        fatchNotify: async function() {
            if (this.loading) return
            this.loading = true
            try {
                const { data } = await this.$requestNotState.post('notify', { news_id: this.$store.state.newsID })
                this.loading = false
                if (data.news.show) {
                    if (this.notifyNews !== null) return
                    this.$store.commit('newsID', data.news.id)
                    this.notifyNews = this.$notify.info({
                        title: data.news.title,
                        message: '<h3 style="text-align: left;cursor: pointer;">' + data.news.message + '</h3><p>' + data.news.create_time + '</p>',
                        duration: 0,
                        dangerouslyUseHTMLString: true,
                        position: 'bottom-right',
                        onClick: () => {
                            this.$router.push('/notice/details/'+data.news.id)
                            this.notifyNews.close()
                        },
                        onClose: () => {
                            this.notifyNews = null
                        }
                    })
                }
                if (data.fund.show) {
                    if(data.audio) this.notifyAudio.play()
                    if (this.notifyFund !== null) return
                    this.notifyFund = this.$notify.info({
                        title: this.$t('navbar.fund'),
                        message: this.$t('fund.notify_message'),
                        duration: 0,
                        onClick: () => {
                            this.$router.push('/fund/log')
                            this.notifyFund.close()
                        },
                        onClose: () => {
                            this.notifyFund = null
                        }
                    })
                }
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header{
    .item{
        cursor: pointer;
    }
}
.header .item{
    color: #fff;
}
.header .item span{
    color: #fff;
}
.header{
    background: rgb(50,50,50);
}
.main{
    background-color: $bgColor;
}
.footer{
    height: auto !important;
    padding: 50px 30px;
    box-shadow: 0 -2px 2px 0 rgb(155 155 155 / 10%);
    nav{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        span{
            margin: 0 8px;
        }
        div{
            cursor: pointer;
        }
    }
    p{
        margin: 0;
        text-align: center;
        font-size: 14px;
    }
}
.footer{
    // background: rgb(50,50,50);
}
</style>